import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    AccordionWithChildren,
    FloatingLabelInput,
    IMerchantPreOnboarding,
    ITableNavigationControl,
    SimpleTable,
    StandardButton,
    StandardButtonWithSpinner,
    StandardIcon,
    TOrganizationType,
    TableItemDetails,
    TextButton,
    addInternalCommentThunk,
    commentSelectors,
    deleteInternalCommentModalThunk,
    ensureInternalCommentsThunk,
    fetchOrganizationCompanyDetailsThunk,
    getClearhausDeliveryDelayOptions,
    getFormattedNumber,
    getFullNameFromFirstAndLastName,
    getKeys,
    getMerchantAcquiringThunk,
    hasValue,
    mapCountryToMarket,
    organizationSelectors,
    sortDescending,
    swMerchantSelectors,
    updateInternalCommentThunk,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import {
    getActivePriceCategoriesFromSubForm,
    mapInvoicingMerchantConfigToCustomPriceSubForm,
} from "../../services/merchantCustomPricesService"
import {
    getMerchantPreOnboardingIcon,
    getMerchantPreOnboardingIconTitle,
    getMerchantPreOnboardingIconTypeFromEventStatusType,
    getMerchantPreOnboardingStatus,
    getmerchantPreOnboardingServiceTitle,
    mapMerchantPreOnboardingDraftToDetails,
} from "../../services/merchantPreOnboardingService"
import { StoreState } from "../../store/StoreState"
import { invoicingSelectors } from "../../store/reducers/invoicingReducer"
import { merchantPreOnboardingSelectors } from "../../store/reducers/merchantPreOnboardingSlice"
import { getInvoicingMerchantConfigThunk, getPublicInvoicingPricesThunk } from "../../store/thunks/invoicingThunks"
import { getMerchantPreOnboardingDetailsThunk } from "../../store/thunks/merchantPreOnboardingThunks"
import { fetchUserByIdThunk } from "../../store/thunks/userThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { getFormattedDate } from "../../util/dateUtil"
import { getValues } from "../../util/objectUtils"
import "./MerchantPreOnboardingDetailsPage.scss"

interface IMerchantPreOnboardingDetailsPageProps {
    onGoBack: () => void
    onEdit: (preOnboardingId: string) => void
    preOnboardingId: string
    navigationControl: ITableNavigationControl<IMerchantPreOnboarding>
    refreshData: () => Promise<void>
}

export const MerchantPreOnboardingDetailsPage = ({
    onGoBack,
    onEdit,
    preOnboardingId,
    navigationControl,
    refreshData,
}: IMerchantPreOnboardingDetailsPageProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [editComment, setEditComment] = useState<string | undefined>()
    const [showComment, setShowComment] = useState<string | undefined>()

    const comments = useSelector((state: StoreState) => commentSelectors.comments(state, preOnboardingId))
    const model = useSelector((state: StoreState) => merchantPreOnboardingSelectors.details(state, preOnboardingId))
    const invoicingMerchantConfig = useSelector((state: StoreState) =>
        invoicingSelectors.merchantConfig(state, model?.preOnboarding.swMerchantId ?? "")
    )
    const publicPrices = useSelector((state: StoreState) =>
        invoicingSelectors.publicPrices(state, mapCountryToMarket(model?.preOnboarding.countryCode ?? "DK"))
    )
    const userData = useSelector(userSelectors.userData)
    const currentUserId = userData?.user.id
    const user = useSelector((state: StoreState) => userSelectors.userById(state, model?.preOnboarding.ownerId ?? ""))
    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(state, model?.preOnboarding.companyRegistrationNumber ?? "")
    )
    const acquiringKycs = useSelector((state: StoreState) =>
        swMerchantSelectors.acquiringKycs(state, model?.preOnboarding.swMerchantId ?? "")
    )

    const dataFromDraft = useMemo(
        () => (model?.preOnboarding.isDraft ? mapMerchantPreOnboardingDraftToDetails(model.preOnboarding) : undefined),
        [model]
    )

    const resolvedModel: typeof model = !model
        ? undefined
        : {
              events: model.events,
              preOnboarding: dataFromDraft?.preOnboarding ?? model.preOnboarding,
              services: dataFromDraft?.services ?? model.services,
              creator: model.creator,
              assigned: model.assigned,
              owner: model.owner,
          }

    const resolvedUser: typeof user = dataFromDraft?.user ?? user
    const resolvedCompany: typeof company = dataFromDraft?.company ?? company
    const resolvedPrices = useMemo(
        () => dataFromDraft?.prices ?? mapInvoicingMerchantConfigToCustomPriceSubForm(invoicingMerchantConfig),
        [dataFromDraft, invoicingMerchantConfig]
    )
    const resolvedAcquiring = dataFromDraft?.acquiringKyc ?? acquiringKycs?.[0]
    const activePriceCategories = getActivePriceCategoriesFromSubForm(resolvedPrices)

    const status = getMerchantPreOnboardingStatus(resolvedModel?.preOnboarding)

    useEffect(() => {
        if (!preOnboardingId) {
            return
        }

        dispatch(getMerchantPreOnboardingDetailsThunk({ preOnboardingId }))
        dispatch(ensureInternalCommentsThunk(preOnboardingId, false))
    }, [preOnboardingId])

    useEffect(() => {
        if (model) {
            dispatch(getPublicInvoicingPricesThunk(mapCountryToMarket(model.preOnboarding.countryCode ?? "DK")))
        }
        if (model && !model.preOnboarding.isDraft) {
            dispatch(
                fetchOrganizationCompanyDetailsThunk(
                    model.preOnboarding.swMerchantId,
                    model.preOnboarding.countryCode,
                    model.preOnboarding.companyRegistrationNumber,
                    TOrganizationType.SwMerchant
                )
            )
            dispatch(fetchUserByIdThunk(model.preOnboarding.ownerId, false))
            dispatch(getInvoicingMerchantConfigThunk(model.preOnboarding.swMerchantId, false))
            dispatch(getMerchantAcquiringThunk(model.preOnboarding.swMerchantId, false))
        }
    }, [model])

    const renderEditComment = (commentId: string) => (
        <div className="merchant-pre-onboarding-details-page__edit-comment">
            <FloatingLabelInput
                className="merchant-pre-onboarding-details-page__edit-comment-input"
                value={editComment}
                onChange={(e) => setEditComment(e.currentTarget.value)}
                placeholder="Comment"
                type="textarea"
            />
            {(commentId === "new" ||
                (currentUserId && !!comments?.find((c) => c.commentId === commentId && c.createdByUserId === currentUserId))) && (
                <div className="merchant-pre-onboarding-details-page__edit-comment-actions">
                    <StandardButton
                        title="Cancel"
                        onClick={() => {
                            setShowComment(undefined)
                            setEditComment(undefined)
                        }}
                        inverted
                        isWide
                        isSmall
                    />
                    <StandardButtonWithSpinner
                        title={commentId === "new" ? "Save & Post" : "Save"}
                        isSmall
                        isWide
                        onClick={async () => {
                            if (!editComment) {
                                return
                            }
                            if (commentId !== "new") {
                                await dispatch(
                                    updateInternalCommentThunk({
                                        relationId: preOnboardingId,
                                        commentId: commentId,
                                        comment: editComment,
                                    })
                                )
                            } else {
                                await dispatch(
                                    addInternalCommentThunk({
                                        relationId: preOnboardingId,
                                        relationType: "MerchantPreOnboarding",
                                        comment: editComment,
                                        area: "Default",
                                    })
                                )
                            }
                            setShowComment(undefined)
                            setEditComment(undefined)
                            await refreshData()
                        }}
                    />
                </div>
            )}
        </div>
    )

    return (
        <TableItemDetails
            item={resolvedModel}
            className="merchant-pre-onboarding-details-page"
            onGoBack={() => onGoBack()}
            backText="Back to all offers"
            getTopLeftConfig={(data) => ({
                className: "merchant-pre-onboarding-details-page__top-left",
                content: (
                    <>
                        {!data.preOnboarding.isCompleted && (
                            <div
                                className={cn(
                                    "merchant-pre-onboarding-details-page__info-box",
                                    status === "draft" && "merchant-pre-onboarding-details-page__info-box--draft",
                                    status === "signed" && "merchant-pre-onboarding-details-page__info-box--signed"
                                )}
                            >
                                {data.preOnboarding.isDraft && (
                                    <span>
                                        You can edit the offer before sending it to the merchant by going to &quot;Edit
                                        offer&quot;.
                                    </span>
                                )}
                                {!data.preOnboarding.isDraft && !data.preOnboarding.isSigned && (
                                    <span>
                                        You can modify the offer before the merchant signs it.
                                        <br />
                                        Make sure to communicate any changes to the merchant.
                                    </span>
                                )}
                                {data.preOnboarding.isSigned && (
                                    <span>The offer is signed, therefore it cannot be modified.</span>
                                )}
                            </div>
                        )}
                        <TextButton
                            className="merchant-pre-onboarding-details-page__top-left-add-comment"
                            text="+ Add internal comment"
                            textDecoration="underline"
                            onClick={async () => {
                                setEditComment("")
                                setShowComment("new")
                            }}
                        />
                        {showComment === "new" && (
                            <div className="merchant-pre-onboarding-details-page__new-comment-headline">
                                New internal comment:
                            </div>
                        )}
                        {showComment === "new" && renderEditComment(showComment)}
                        {(comments ?? []).length > 0 && (
                            <div className="merchant-pre-onboarding-details-page__comments">
                                {comments?.map((c, index) => (
                                    <>
                                        {index > 0 && <hr />}
                                        {showComment !== c.commentId && (
                                            <div key={c.commentId} className="merchant-pre-onboarding-details-page__comment">
                                                <div className="merchant-pre-onboarding-details-page__comment-text">
                                                    {c.comment}
                                                </div>
                                                <div className="merchant-pre-onboarding-details-page__comment-data">
                                                    <span className="merchant-pre-onboarding-details-page__comment-owner">
                                                        {c.createdByEmail.split("@")[0]},
                                                    </span>
                                                    <span className="merchant-pre-onboarding-details-page__comment-created">
                                                        {getFormattedDate(c.created, "dash-international")}
                                                        {c.lastUpdated && c.created !== c.lastUpdated && (
                                                            <span>
                                                                , <i>edited</i>
                                                            </span>
                                                        )}
                                                    </span>
                                                    {c.createdByUserId === currentUserId && (
                                                        <span className="merchant-pre-onboarding-details-page__comment-actions">
                                                            <TextButton
                                                                text="Edit"
                                                                textDecoration="underline"
                                                                onClick={async () => {
                                                                    setEditComment(c.comment)
                                                                    setShowComment(c.commentId)
                                                                }}
                                                            />
                                                            <span className="ml-2 mr-2">|</span>
                                                            <TextButton
                                                                text="Delete"
                                                                textDecoration="underline"
                                                                onClick={async () => {
                                                                    await dispatch(
                                                                        deleteInternalCommentModalThunk({
                                                                            commentId: c.commentId,
                                                                            relationId: preOnboardingId,
                                                                        })
                                                                    )
                                                                    await refreshData()
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {showComment === c.commentId && renderEditComment(c.commentId)}
                                    </>
                                ))}
                            </div>
                        )}
                    </>
                ),
            })}
            getTopRightConfig={(data) => ({
                mainRow: {
                    title: "Merchant",
                    value: data.preOnboarding.merchantName,
                    extraContent: (
                        <div className={cn("merchant-pre-onboarding-details-page__status-outer")}>
                            <div
                                className={cn(
                                    "merchant-pre-onboarding-details-page__status",
                                    status === "draft" && "merchant-pre-onboarding-details-page__status--draft",
                                    status === "signed" && "merchant-pre-onboarding-details-page__status--signed",
                                    status === "completed" && "merchant-pre-onboarding-details-page__status--completed"
                                )}
                            >
                                {status === "draft" && <div>DRAFT</div>}
                                {status === "sent" && <div>SENT</div>}
                                {status === "signed" && <div>SIGNED</div>}
                                {status === "completed" && <div>COMPLETED</div>}
                            </div>
                        </div>
                    ),
                },
                rows: [
                    { type: "titleValue", title: "Created by", value: data.creator.email },
                    data.assigned ? { type: "titleValue", title: "Assigned to", value: data.assigned.email } : undefined,
                    {
                        type: "titleValue",
                        title: "Est. value",
                        value: resolvedAcquiring?.turnoverMonthly
                            ? resolvedAcquiring.currency + " " + getFormattedNumber(resolvedAcquiring.turnoverMonthly)
                            : data.preOnboarding.estimatedValueCurrency +
                              " " +
                              getFormattedNumber(data.preOnboarding.estimatedValue),
                    },
                    { type: "titleValue", title: "Billing", value: activePriceCategories.length > 0 ? "Custom" : "Standard" },
                    {
                        type: "titleValue",
                        title: "Services",
                        value: (
                            <>
                                {getKeys(data.services)
                                    .filter((s) => !!data.services[s])
                                    .map((s) => getmerchantPreOnboardingServiceTitle(s))
                                    .filter(hasValue)
                                    .map((serviceTitle) => (
                                        <div
                                            className="merchant-pre-onboarding-details-page__top-right-service"
                                            key={serviceTitle}
                                        >
                                            {serviceTitle}
                                        </div>
                                    ))}
                            </>
                        ),
                    },
                    data.preOnboarding.isSigned
                        ? undefined
                        : {
                              type: "custom",
                              className: "merchant-pre-onboarding-details-page__actions",
                              content: (
                                  <>
                                      <StandardButton invertedBlue isSmall className="fr" onClick={() => onEdit(preOnboardingId)}>
                                          Edit offer
                                      </StandardButton>
                                  </>
                              ),
                          },
                ],
            })}
            renderBottom={(data) => (
                <>
                    <AccordionWithChildren header="History" leftAlignHeader arrowNextToHeader isHeaderSectionTitle>
                        <SimpleTable
                            items={sortDescending(data.events, (e) => e.created)}
                            renderHeaderCells={() => [{ content: "Date" }, { content: "Status" }, { content: "Note" }]}
                            renderRowCells={(evt) => [
                                { content: getFormattedDate(evt.created, "slashes") },
                                {
                                    className: "merchant-pre-onboarding-details-page__history-status",
                                    content: !evt.statusType ? (
                                        "-"
                                    ) : (
                                        <span className="merchant-pre-onboarding-details-page__history-status-inner">
                                            <StandardIcon
                                                className="merchant-pre-onboarding-details-page__history-status-inner-icon"
                                                svgComponent={getMerchantPreOnboardingIcon(
                                                    getMerchantPreOnboardingIconTypeFromEventStatusType(evt.statusType)
                                                )}
                                                title={getMerchantPreOnboardingIconTitle(
                                                    getMerchantPreOnboardingIconTypeFromEventStatusType(evt.statusType)
                                                )}
                                                padding={3}
                                                width={30}
                                                backgroundType="circle"
                                                backgroundColor="#e4fafb"
                                                componentForegroundColor="#1A496D"
                                            />
                                            <span>{evt.statusType}</span>
                                        </span>
                                    ),
                                },
                                { content: evt.note },
                            ]}
                        />
                    </AccordionWithChildren>
                    <AccordionWithChildren
                        className="merchant-pre-onboarding-details-page__full-info"
                        header="Full merchant information"
                        leftAlignHeader
                        arrowNextToHeader
                        isHeaderSectionTitle
                    >
                        <div className="merchant-pre-onboarding-details-page__full-info-inner">
                            <FullInfoSection
                                title="1. Company details"
                                content={[
                                    [
                                        data.preOnboarding.merchantName,
                                        "CVR: " + data.preOnboarding.companyRegistrationNumber,
                                        resolvedCompany?.companyType,
                                    ],
                                    [
                                        resolvedCompany?.companyAddress,
                                        resolvedCompany?.companyPostcode
                                            ? `${resolvedCompany.companyPostcode}, ${resolvedCompany.companyCity}`
                                            : undefined,
                                        data.preOnboarding.countryCode,
                                    ],
                                    [resolvedCompany?.companyPhone, resolvedCompany?.companyEmail],
                                    [
                                        data.preOnboarding.estimatedValue &&
                                            `Est. value: ${data.preOnboarding.estimatedValueCurrency} ${getFormattedNumber(
                                                data.preOnboarding.estimatedValue
                                            )}`,
                                    ],
                                    [
                                        data.preOnboarding.merchantKycMessage ? (
                                            <React.Fragment key="kycMessage">
                                                <b>Note to merchant</b>: {data.preOnboarding.merchantKycMessage}
                                            </React.Fragment>
                                        ) : undefined,
                                    ],
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="2. Business model"
                                content={[
                                    [resolvedAcquiring?.businessDescription],
                                    [resolvedAcquiring?.tradeName ? `Trade name: ${resolvedAcquiring.tradeName}` : undefined],
                                    [resolvedAcquiring?.isSellingSubscriptions ? "Sells subscriptions" : undefined],
                                    [
                                        resolvedAcquiring?.isSellingPhysicalProducts ? "Sells physical products" : undefined,
                                        resolvedAcquiring?.deliveryDelay
                                            ? getClearhausDeliveryDelayOptions().find(
                                                  (o) => o.value === resolvedAcquiring.deliveryDelay
                                              )?.text
                                            : undefined,

                                        resolvedAcquiring?.isUsingDropShipping ? "Uses drop shipping" : undefined,
                                    ],
                                    [
                                        resolvedAcquiring?.delayComment ? `Delay comment: ` : undefined,
                                        resolvedAcquiring?.delayComment,
                                    ],
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="3. Turnover"
                                content={[
                                    [
                                        resolvedAcquiring?.turnoverMonthly || data.preOnboarding.estimatedValue
                                            ? `Monthly turnover (average): ${
                                                  resolvedAcquiring?.currency || data.preOnboarding.estimatedValueCurrency
                                              } ${getFormattedNumber(
                                                  resolvedAcquiring?.turnoverMonthly || data.preOnboarding.estimatedValue
                                              )}`
                                            : undefined,
                                        resolvedAcquiring?.avgTransactionAmount
                                            ? `Average transaction amount: ${resolvedAcquiring?.currency} ${getFormattedNumber(
                                                  resolvedAcquiring?.avgTransactionAmount
                                              )}`
                                            : undefined,
                                    ],
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="4. Contact info"
                                content={[
                                    [
                                        getFullNameFromFirstAndLastName(resolvedUser?.firstName, resolvedUser?.lastName),
                                        resolvedUser?.phone,
                                        resolvedUser?.email,
                                    ],
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="5. Services"
                                content={[
                                    [
                                        ...getKeys(data.services)
                                            .filter((s) => !!data.services[s])
                                            .map((s) => getmerchantPreOnboardingServiceTitle(s))
                                            .filter(hasValue),
                                    ],
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="6. Custom message (email)"
                                content={[[<i key="merchantEmailMessage">{data.preOnboarding.merchantEmailMessage}</i>]]}
                            />
                            <hr />
                            <FullInfoSection
                                title="7. Custom prices and discounts"
                                content={[
                                    activePriceCategories.map((cat) => {
                                        const price = publicPrices?.find((pp) => pp.priceCategory === cat)
                                        if (!price) {
                                            return
                                        }
                                        const override = resolvedPrices.priceOverrides[cat]
                                        const tempPrice = getValues(resolvedPrices.customPrices).find(
                                            (cp) => cp?.priceCategory === cat
                                        )
                                        return (
                                            <div className="d-flew flex-column" key={cat}>
                                                {override?.price && (
                                                    <div>
                                                        {price.description}:{" "}
                                                        {getFormattedNumber(parseFloat(override.price), {
                                                            numberFormat: "forceTwoDecimals",
                                                        })}{" "}
                                                        {price.isPercent ? "%" : price.currency}
                                                    </div>
                                                )}
                                                {tempPrice?.price && (
                                                    <div>
                                                        {price.description}:{" "}
                                                        {getFormattedNumber(parseFloat(tempPrice.price), {
                                                            numberFormat: "forceTwoDecimals",
                                                        })}{" "}
                                                        {price.isPercent ? "%" : price.currency} for {tempPrice.periods}{" "}
                                                        {tempPrice.periods === "1" ? "month" : "months"}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }),
                                ]}
                            />
                            <hr />
                            <FullInfoSection
                                title="8. Custom message (Offer)"
                                content={[[<i key="merchantOfferMessage">{data.preOnboarding.merchantOfferMessage}</i>]]}
                            />
                        </div>
                    </AccordionWithChildren>
                </>
            )}
            navigationControl={navigationControl}
            getNavigationTitle={(preOnboarding, isPrevious) =>
                isPrevious ? `Previous Offer (${preOnboarding.merchantName})` : `Next Offer (${preOnboarding.merchantName})`
            }
        />
    )
}

function FullInfoSection(props: { title: string; content: React.ReactNode[][] }) {
    return (
        <div className="merchant-pre-onboarding-details-page__full-info-section">
            <div className="merchant-pre-onboarding-details-page__full-info-section-title">{props.title}</div>
            <div className="merchant-pre-onboarding-details-page__full-info-section-content">
                {props.content
                    .filter((c) => !!c.find((d) => !!d))
                    .map((subSection, index) => {
                        return (
                            <div
                                key={index}
                                className="merchant-pre-onboarding-details-page__full-info-section-content-subsection"
                            >
                                {subSection.filter(hasValue).map((c, indexInner) => {
                                    return (
                                        <div
                                            key={indexInner}
                                            className="merchant-pre-onboarding-details-page__full-info-section-content-subsection-data"
                                        >
                                            {c}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
